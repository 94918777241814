//  NETAO Navbar v2.1
//  Element auquel on va appliquer un margin bottom pour simuler la place que prenait le fixedElement
    // let marginNavbar = document.querySelector("header.header[role='banner'] > .bar-container:first-child");

//  Element à fixer, selecteurs css classiques.
//  L'élément qu'on souhaite fixer en haut de la page.
    // let fixedNavbar = document.querySelector("header.header[role='banner'] > .bar-container:nth-child(2n)");

//  Définition du parent.
    let parent = document.querySelector('#navbar-wrapper1');
    parent.style.minHeight = `${parent.offsetHeight}px`;

//  L'enfant doit être le dernier élément du parent
    let enfant = parent.querySelector('div:last-child');

//  Le logo IMG, selecteurs css classiques.
//  L'élément IMG a qui on souhaite modifier la taille
    let logo =  document.querySelector('#nav-bar').querySelector('.navbar-logo');

//  Le container du logo
//  L'élément contenant le logo, ici est utilisé pour réduire le padding.
    let logoContainer = logo.parentNode.parentNode;

//  Calcul la place que prend le fixedElement
    // const fixedNavbarOffset = fixedNavbar.offsetHeight;

//  Objet de configuration
    let config = {
        //  Breakpoints
        breakpointScreen: 'screen and (max-width: 926px)',
        // breakpointNav: fixedNavbar.offsetTop,
        breakpointNav: enfant.offsetTop,

        //  Class
        //  Ajout des classes qui gère le responsive.
        responsiveClass: 'fixed-navbar',
        defaultClass: '',

        //  Logo
        logo: {
            //  Transition
            transition: '.2s',
            //  Width
            width: {
                responsive: '40%',
                default: '100%',
            },
            //  Padding
            padding: {
                responsive: '0',
                default: '.7em',
            },

        }
    }

//  Appliquer la transition désirée.
    logo.style.transition = config.logo.transition;

//  Fonctions qui servent à la modification, Responsive et Default.
    let func = {
        responsiveClass: () => {
            if(config.responsiveClass.length > 0 && window.scrollY >= config.breakpointNav){
                // marginNavbar.style.margin = `0 0 ${fixedNavbarOffset}px 0`; 
                // fixedNavbar.classList.add(config.responsiveClass)
                enfant.classList.add(config.responsiveClass)

            }
            config.defaultClass.length > 0 && window.scrollY >= config.breakpointNav ? enfant.classList.remove(config.defaultClass) : null
        },
        defaultClass: () => {
            if( config.responsiveClass.length > 0){
                // marginNavbar.style.margin = `0`
                // fixedNavbar.classList.remove(config.responsiveClass)
                enfant.classList.remove(config.responsiveClass)
            }
            config.defaultClass.length > 0 ?enfant.classList.add(config.defaultClass) : null
        },
        responsiveLogo: () =>{
            logo.style.maxWidth = window.scrollY >= config.breakpointNav ? config.logo.width.responsive : config.logo.width.default;
            logoContainer.style.padding = window.scrollY >= config.breakpointNav ? config.logo.padding.responsive : config.logo.padding.default;
        },
        defaultLogo: () => {
            logo.style.maxWidth = config.logo.width.default;
            logoContainer.style.padding = config.logo.padding.default;
        }
    }

//  Fin des objets de configuration.

// Appel des fonctions, rien est à modifier ici.

//  Fonction déclenchée par le listener.
    let isUp = () => {
        if(config.breakpointNav <= window.scrollY){
            func.responsiveClass();
            func.responsiveLogo();
        }
        else{
            func.defaultClass();
            func.defaultLogo();
        }    
    }

//  Gestion du responsive
    let mql = window.matchMedia(config.breakpointScreen);

    if(!mql.matches)
        window.addEventListener('scroll', isUp);

    mql.addEventListener("change", e => {
        if(!e.matches){ 
            window.addEventListener('scroll', isUp);
            func.responsiveClass();
            func.responsiveLogo();
        } 
        else {
            window.removeEventListener('scroll', isUp)
            func.defaultClass();
            func.defaultLogo();
        }
    })
    

//  Search

let magnifyingglass = document.querySelector("#nav-bar #magnifyingglass").parentNode;
let searchbar = document.querySelector("#search");
searchbar.querySelector(".search-field").setAttribute("placeholder", "Recherche...")
searchbar.querySelector(".search-submit").remove()
magnifyingglass.addEventListener("click", (event) => {
    event.preventDefault()
    if(searchbar.getAttribute("position") == "visible"){
        searchbar.setAttribute("position", "invisible")
        searchbar.style.opacity = "0";
        searchbar.style.pointerEvents = "none";
    } else {
        searchbar.setAttribute("position", "visible")
        searchbar.style.opacity = "1";
        searchbar.style.pointerEvents = "auto";
    }

})